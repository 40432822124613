<template>
<v-bottom-sheet :value="true" inset persistent :hide-overlay="hideOverlay">
			<v-card>
				<v-timeline dense>
					<v-slide-x-transition group>
						<v-timeline-item
							fill-dot
							color="transparent"
							class="align-center"
							v-for="(val, key) in value"
							:key="key"
						>
							<template v-slot:icon>
								<v-progress-circular
									v-if="
										!isNaN(val.max) &&
										(val.progress == null || val.progress == undefined)
									"
									:val="(val.actual * 100) / val.max"
									color="secondary"
								></v-progress-circular>
								<v-icon v-else-if="val.progress === false">mdi-minus</v-icon>
								<v-icon color="success" v-else-if="val.progress === true"
									>mdi-check</v-icon
								>
								<v-icon color="error" v-else-if="val.progress === 'error'"
									>mdi-close-octagon-outline</v-icon
								>
								<v-progress-circular
									v-else-if="val.progress === 'loading'"
									indeterminate
									color="secondary"
								></v-progress-circular>
							</template>
							<h4
								:class="[
									val.progress == 'error' ? 'error--text' : '',
									val.progress === true ? 'success--text' : '',
									val.progress === false ? 'grey--text' : '',
								]"
							>
								{{ val.texto }}
							</h4>
						</v-timeline-item>
					</v-slide-x-transition>
				</v-timeline>
			</v-card>
		</v-bottom-sheet>
</template>

<script>
export default {
	name: 'LoadingSteps',
	props: {
		value: Object,
		hideOverlay: {type: Boolean, default: true}
	}
}
</script>